import {DiFormConfigDirective} from 'app/common/di-forms/directives/di-form.directive';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
    HostBinding,
    forwardRef,
    Optional,
    ViewChild,
    HostListener,
    ChangeDetectorRef,
    ElementRef
} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap, takeUntil} from 'rxjs/operators';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {DiFormElement} from 'app/common/di-forms/shared/di-form-element';
import {NzSelectComponent} from 'ng-zorro-antd/select';
import {NzFormItemComponent} from 'ng-zorro-antd/form';
import {debounce} from 'lodash';
import {LogService} from 'app/blocks/service/log.service';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: DiFormElement,
    useExisting: forwardRef(() => DiSearchSelectComponent)
};
/**
 * [nzFilterOption]="nzFilterOption" This allows us to filter the options separately on the UI.
 * Add if required later
 */
@Component({
    selector: 'di-search-select',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}" *ngIf="this.showField">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span [ngClass]="field">
                        {{ fieldLabel$ | async }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                    <nz-select
                        id="{{ 'di-search-select-' + field + idSuffix }}"
                        [nzDisabled]="disabled"
                        [ngStyle]="{width: width, 'max-width': width, 'z-index': 10000}"
                        [nzAutoFocus]="focus"
                        nzShowSearch
                        nzServerSearch
                        [nzShowArrow]="false"
                        (nzOnSearch)="search($event)"
                        (ngModelChange)="onChange($event)"
                        formControlName="{{ field }}"
                        [compareWith]="compareFn"
                        [nzAllowClear]="true"
                        (nzOpenChange)="registerOpen($event)"
                        [nzPlaceHolder]="placeHolder">
                        <ng-container *ngFor="let option of options$ | async">
                            <nz-option *ngIf="!loading" [nzValue]="getValue(option)" [nzLabel]="getLabel(option)"></nz-option>
                        </ng-container>
                        <nz-option *ngIf="loading" nzDisabled nzCustomContent>
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./di-search-select.component.scss'],
    providers: [formElementProvider],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiSearchSelectComponent extends DiFormElement implements OnInit {
    @ViewChild(NzSelectComponent, {static: true})
    nzSelectElement: NzSelectComponent;

    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() width = '100%';
    @Input() align: 'start' | 'center' | 'end' = 'start';
    @Input() focus = false;
    @Input() disabled = false;

    @Input() field: string;
    @Input() idSuffix = '';
    @Input() displayAttribute = null;
    @Input() valueAttribute: string | null;
    @Input() valuesParentProperty: string | null; // used if the object's value is nested, ie object.id instead of just id
    @Input() options$: Observable<any>; // un-initalised set of options
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() minimumSearchLength = 2;
    @Input() customSearchFn: (term: string) => Observable<any[]>;
    @Input() customPostSearchFn: (thisElement: NzSelectComponent) => void;
    @Input() customDisplayFn: (item: any) => string = null;
    @Input() compareFn: (item1, item2) => boolean;
    @Input() placeHolder = 'Search';
    @Input() booleanInput = false;
    @Input() enableTracking = false;
    @Input() showField = true;
    @Input() roleSettingSuffix = '';

    @Output() selectionChange = new EventEmitter<any>();
    @Output() tabPress = new EventEmitter<any>();

    searchHappened = false;
    openHappened = false;
    faInfoIcon = faInfoCircle;
    loading = false;
    @Input() userInput$ = new Subject<string>();

    @HostListener('keydown.Tab', ['$event'])
    onTabPress($event: KeyboardEvent): void {
        this.tabPress.emit($event);
    }

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        private _logService: LogService,
        @Optional() diForm: DiFormConfigDirective,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, diForm);
    }

    focusNow(): void {
        if (this.nzSelectElement) {
            this.nzSelectElement.focus();
        }
    }

    registerOpen(opened: Event): void {
        if (!opened) {
            // i.e. dropdown was closed
            setTimeout(() => this.focusNow(), 0);
        }
    }

    getFieldName(): string {
        return this.field;
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!this.compareFn) {
            this.compareFn = this.defaultCompareFn;
        }
        // this.initOptionSearch();
    }

    getLabel(option: any): string {
        if (option) {
            if (this.customDisplayFn) {
                return this.customDisplayFn(option);
            } else if (this.displayAttribute) {
                return option[this.displayAttribute];
            }
        }

        return null;
    }

    getValue(option: any): string {
        // only first value attribute specified
        if (!this.valuesParentProperty) {
            return this.valueAttribute ? option[this.valueAttribute] : option;
        }

        // first and second value attribute specified
        return option[this.valuesParentProperty][this.valueAttribute];
    }

    defaultCompareFn = (c1: any, c2: any): boolean => {
        let isMatch = false;
        if (c1 && c2) {
            if (this.valueAttribute) {
                const firstValue = typeof c1 === 'object' ? c1[this.valueAttribute] : c1;
                const secondValue = typeof c2 === 'object' ? c2[this.valueAttribute] : c2;
                isMatch = firstValue === secondValue;
            } else if (c1 && c2 && typeof c1 === 'object' && typeof c2 === 'object') {
                isMatch = c1.id === c2.id;
            } else {
                isMatch = c1 === c2;
            }
        }
        return isMatch;
    };

    search(value: string): void {
        if (value === '') {
            return;
        }

        if (!this.searchHappened) {
            this.initOptionSearch();
        }

        if (value && value.length >= this.minimumSearchLength) {
            this.loading = true;
            setTimeout(() => this.userInput$.next(value), 10);
        }
    }

    private initOptionSearch(): void {
        if (this.customSearchFn) {
            this.options$ = concat(
                of([]), // default items
                this.userInput$.pipe(
                    debounceTime(200),
                    distinctUntilChanged(),
                    switchMap((term) =>
                        this.customSearchFn(term).pipe(
                            catchError(() => of([])), // empty list on error
                            tap(() => (this.loading = false))
                        )
                    ),
                    tap(() => {
                        if (this.customPostSearchFn) {
                            this.customPostSearchFn(this.nzSelectElement);
                        }
                    })
                )
            );
            this.searchHappened = true;
        }
    }

    private debouncedLog = debounce((value) => {
        this._logService.log('Search Select Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    onChange(selectedItem: any): void {
        if (!selectedItem) {
            this.searchHappened = false;
        }
        this.selectionChange.emit(selectedItem);
        if (this.enableTracking) {
            this.debouncedLog(selectedItem);
        }
        // if (this.searchHappened || this.openHappened) {
        //     setTimeout(() => this.focusNow(), 0);
        // }
    }
}
